<template>
  <div class="welcome">

  </div>
</template>

<script>
export default {
  name: "welcome"
}
</script>

<style scoped>
.welcome {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/welcome.png");
  background-size: cover;
}
</style>